import { Box, Typography } from '@mui/material';
import { IOSSwitch } from 'TGComponents/global/Inputs';

export default function TGSwitch({
  label,
  checked,
  onChange,
  disabled = false,
  labelPosition = 'right',
}) {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      gap='10px'
      flexDirection={labelPosition === 'right' ? 'row' : 'row-reverse'}
    >
      <IOSSwitch checked={checked} onChange={onChange} disabled={disabled} />
      <Typography fontSize={'14px'} color='#555555'>
        {label}
      </Typography>
    </Box>
  );
}
