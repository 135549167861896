// menuItems.js
import { tools } from 'assets/svgs';
import { integrations } from 'assets/svgs';
import { people } from 'assets/svgs';
import {
  home,
  events,
  products,
  LayoutEmail,
  vendas,
  usuario,
  legendarioIcon,
  estrela,
  settings,
  finance,
  checkIn,
  chartIcon,
  medal,
} from 'assets/svgs';

const menuItems = (user) =>
  [
    {
      text: 'Início',
      icon: home,
      to: '/',
      roles: [
        'PRODUTOR-OPERACIONAL',
        'PRODUTOR-ADMIN',
        'PROPRIETARIO-SOCIO',
        'PRODUTOR-FINANCEIRO',
      ],
      adm: true,
    },
    //{
    //  text: 'Eventos',
    //  icon: events,
    //  to: '',
    //  roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
    //  children: [
    //    {
    //      text: 'Novo evento',
    //      to: 'event/add',
    //      adm: true,
    //      roles: [
    //        'PRODUTOR-OPERACIONAL',
    //        'PRODUTOR-ADMIN',
    //        'PROPRIETARIO-SOCIO',
    //      ],
    //    },
    //    {
    //      text: 'Cadastrados',
    //      to: 'event/registered',
    //      adm: true,
    //      roles: [
    //        'PRODUTOR-OPERACIONAL',
    //        'PRODUTOR-ADMIN',
    //        'PROPRIETARIO-SOCIO',
    //      ],
    //    },
    //    {
    //      text: 'Participantes',
    //      to: 'attendee',
    //      adm: true,
    //      roles: [
    //        'PRODUTOR-OPERACIONAL',
    //        'PRODUTOR-ADMIN',
    //        'PROPRIETARIO-SOCIO',
    //      ],
    //    },
    //    {
    //      text: 'Cupom',
    //      to: 'event/coupon',
    //      adm: true,
    //      roles: [
    //        'PRODUTOR-OPERACIONAL',
    //        'PRODUTOR-ADMIN',
    //        'PROPRIETARIO-SOCIO',
    //      ],
    //    },
    //  ],
    //},
    // {
    //   text: 'Produtos v2',
    //   icon: products,
    //   to: 'produto/index',
    //   isBeta: true,
    //   adm: true,
    //   roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO'],
    // },
    {
      text: 'Eventos',
      icon: events,
      to: 'eventos',
      roles: [
        'PRODUTOR-OPERACIONAL',
        'PRODUTOR-ADMIN',
        'PROPRIETARIO-SOCIO',
        'PRODUTOR-FINANCEIRO',
      ],
      adm: true,
    },
    //    user?.grupo?.legendario
    //      ? {
    //          text: 'Legendários',
    //          icon: legendarioIcon,
    //          adm: true,
    //          to: 'legendarios',
    //
    //          roles: [
    //            'PRODUTOR-OPERACIONAL',
    //            'PRODUTOR-ADMIN',
    //            'PROPRIETARIO-SOCIO',
    //            'PRODUTOR-FINANCEIRO',
    //          ],
    //        }
    //      : null,
    {
      text: 'Produtos',
      icon: products,
      to: 'produto',
      roles: [
        'PRODUTOR-OPERACIONAL',
        'PRODUTOR-ADMIN',
        'PROPRIETARIO-SOCIO',
        'PRODUTOR-FINANCEIRO',
      ],
      adm: true,
    },
    {
      text: 'Layout de e-mail',
      icon: LayoutEmail,
      to: 'mailing',
      adm: true,
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
    },
    {
      text: 'Financeiro',
      icon: finance,
      to: 'financial/summary',
      adm: false,
      roles: ['PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO', 'PRODUTOR-FINANCEIRO'],
      // children: [
      //   {
      //     text: 'Minhas vendas',
      //     to: 'financial/sales',
      //     adm: true,
      //     roles: [
      //       'PRODUTOR-OPERACIONAL',
      //       'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO',
      //       'PRODUTOR-FINANCEIRO',
      //     ],
      //   },
      //   //{
      //   //  text: 'Financeiro',
      //   //  to: 'financial/summary',
      //   //  adm: true,
      //   //  roles: [
      //   //    'PRODUTOR-OPERACIONAL',
      //   //    'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO',
      //   //    'PRODUTOR-FINANCEIRO',
      //   //  ],
      //   //},
      //   {
      //     text: 'Relatórios',
      //     to: 'relatorio/enotas',
      //     adm: true,
      //     roles: [
      //       'PRODUTOR-OPERACIONAL',
      //       'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO',
      //       'PRODUTOR-FINANCEIRO',
      //     ],
      //   },
      //   {
      //     text: 'Carteira',
      //     to: 'financial/wallet',
      //     adm: true,
      //     roles: [
      //       'PRODUTOR-OPERACIONAL',
      //       'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO',
      //       'PRODUTOR-FINANCEIRO',
      //     ],
      //   },
      //   {
      //     text: 'Reembolso',
      //     to: 'financial/refunds',
      //     adm: true,
      //     roles: [
      //       'PRODUTOR-OPERACIONAL',
      //       'PRODUTOR-ADMIN','PROPRIETARIO-SOCIO',
      //       'PRODUTOR-FINANCEIRO',
      //     ],
      //   },
      // ],
    },
    {
      text: 'Vendas',
      icon: vendas,
      //to: '/financeiro',
      adm: true,
      roles: [
        'PRODUTOR-OPERACIONAL',
        'PRODUTOR-ADMIN',
        'PROPRIETARIO-SOCIO',
        'PRODUTOR-FINANCEIRO',
      ],
      children: [
        {
          text: 'Minhas vendas',
          to: 'report/sales',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
        },
        {
          text: 'Recuperadas',
          to: 'report/recoveredsales',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
        },
        {
          text: 'Assinaturas',
          to: 'financeiro/vendas',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
        },
        {
          text: 'Reembolso',
          to: 'financial/reimbursement',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
        },
        {
          text: 'Payflex',
          to: 'report/payflexsales',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
        },
      ],
    },
    {
      text: 'Afiliados',
      icon: people,
      to: '',
      adm: true,
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
      children: [
        {
          text: 'Meus Programas',
          to: 'afiliados/index',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
          ],
        },
        {
          text: 'Minhas afiliações',
          to: 'afiliados/afiliacoes',
          adm: true,
          roles: [
            'PRODUTOR-OPERACIONAL',
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
          ],
        },
      ],
    },
    {
      text: 'Relatórios',
      icon: chartIcon,
      to: 'relatorios',
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
      adm: true,
    },

    {
      text: 'Webhooks',
      icon: tools,
      to: 'webhooks',
      adm: true,
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
    },
    {
      text: 'Integrações',
      icon: integrations,
      to: 'integrations/configuration',
      adm: true,
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
    },
    {
      text: 'Automações',
      icon: tools,
      to: 'integrations/automation',
      adm: true,
      roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PROPRIETARIO-SOCIO'],
    },

    user?.super_admin
      ? {
          text: 'Usuários',
          icon: usuario,
          adm: true,
          roles: [],
          children: [
            {
              text: 'Cadastrados',
              to: 'user',
              adm: true,
            },
            {
              text: 'Novo usuário',
              to: 'user/add',
              adm: true,
            },
          ],
        }
      : null,
    user?.super_admin
      ? {
          text: 'Produtoras',
          icon: finance,
          adm: true,
          roles: [],
          children: [
            {
              text: 'Cadastradas',
              to: 'producer',
              adm: true,
            },
            {
              text: 'Nova produtora',
              to: 'producer/new',
              adm: true,
            },
            {
              text: 'Taxas',
              to: 'rate',
              adm: true,
            },
          ],
        }
      : null,

    user?.super_admin
      ? {
          text: 'BackOffice',
          icon: estrela,
          adm: true,
          roles: [],
          children: [
            {
              text: 'Relatório',
              to: 'backoffice/sales',
              adm: true,
            },
            {
              text: 'Repasses',
              to: 'backoffice/withdraw/producer',
              adm: true,
            },
            {
              text: 'Estorno',
              to: 'backoffice/reversal',
              adm: true,
            },
            {
              text: 'Reembolso',
              to: 'financial/reimbursement',
              adm: true,
            },
          ],
        }
      : null,
    !user?.super_admin
      ? {
          text: 'Configurações',
          icon: settings,
          adm: false,
          roles: [
            'PRODUTOR-ADMIN',
            'PROPRIETARIO-SOCIO',
            'PRODUTOR-FINANCEIRO',
          ],
          children: [
            {
              text: 'Perfil',
              to: 'perfil',
              adm: false,
              roles: [
                'PRODUTOR-ADMIN',
                'PROPRIETARIO-SOCIO',
                'PRODUTOR-FINANCEIRO',
              ],
            },

            {
              text: 'Usuários',
              to: 'user',
              adm: false,
              roles: [
                'PRODUTOR-ADMIN',
                'PROPRIETARIO-SOCIO',
                'PRODUTOR-FINANCEIRO',
              ],
            },
            {
              text: 'Smtp',
              to: 'smtp',
              adm: false,
              roles: [
                'PRODUTOR-ADMIN',
                'PROPRIETARIO-SOCIO',
                'PRODUTOR-FINANCEIRO',
              ],
            },
          ],
        }
      : null,
    user?.super_admin
      ? {
          text: 'Compliance',
          icon: medal,
          adm: true,
          children: [
            {
              text: 'Contas bancárias',
              to: 'compliance/index',
              adm: true,
            },
            {
              text: 'KYC',
              to: 'compliance/kyc',
              adm: true,
            },
            {
              text: 'Documentos',
              to: 'compliance/documentoscopia',
              adm: true,
            },
          ],
        }
      : null,
  ].filter(Boolean);
export default menuItems;
