import React from 'react';
import { useMediaQuery } from '@mui/material';
import { shortenText } from '../../lib/utils';

const TGShortenText = ({
  text,
  screensLengths = [10, 14, 16, 18, 20, 250],
}) => {
  const isXs = useMediaQuery('(min-width:0px)');
  const isSm = useMediaQuery('(min-width:600px)');
  const isMd = useMediaQuery('(min-width:900px)');
  const isLg = useMediaQuery('(min-width:1400px)');
  const isXl = useMediaQuery('(min-width:1600px)');
  const isXxl = useMediaQuery('(min-width:2000px)'); // Fixed media query

  let maxLength = screensLengths[2];

  if (isXxl) {
    maxLength = screensLengths[5];
  } else if (isXl) {
    maxLength = screensLengths[4];
  } else if (isLg) {
    maxLength = screensLengths[3];
  } else if (isMd) {
    maxLength = screensLengths[2];
  } else if (isSm) {
    maxLength = screensLengths[1];
  } else if (isXs) {
    maxLength = screensLengths[0];
  }

  const shortenedText = shortenText(text, maxLength);

  return <>{shortenedText}</>;
};

export default TGShortenText;
