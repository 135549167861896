import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import * as singleActions from '../../../actions/singleActions';

import '../login.css';

import { Box, Typography, useMediaQuery } from '@mui/material';
import TGButton from 'TGComponents/global/TGButton';
import TGCheckbox from 'TGComponents/global/TGCheckbox';
import TGTextField from 'TGComponents/global/TGTextField';
import { registerSchema } from '../schemas/AuthSchema';

const initialValues = {
  nome: '',
  email: '',
  confirmeEmail: '',
  senha: '',
};

const RegisterContent = () => {
  const [formValues, setformValues] = useState(initialValues);
  const getFormData = (values) => {};
  const [showPassword, setShowPassword] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);

  const isMobile = useMediaQuery('(max-width:1080px)');

  const [searchParams] = useSearchParams();

  console.log('qyery param', searchParams.get('u'));

  const { executeRecaptcha } = useGoogleReCaptcha();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const errors = useSelector(
    (state) => state.single?.response?.usuario?.errors || {}
  );
  const saved = useSelector(
    (state) => state.single?.response?.usuario?.status || false
  );
  const loading = useSelector((state) => state.single.loading === 'usuario');

  const postSingle = (payload) =>
    dispatch(singleActions.postSingle(`/usuarios`, payload, 'usuario'));

  const clearSingle = (single) => dispatch(singleActions.clearSingle(single));

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (saved) {
      navigate('/login', { replace: true });
      clearSingle('usuario');
    }
  }, [saved]);

  //   REGISTER FORM
  const handleSubmitForm = async (values) => {
    const token = await executeRecaptcha('register');
    values['cpt'] = token;

    const uidc = searchParams.get('u');

    if (uidc) {
      values['uidc'] = uidc;
    }

    postSingle({
      ...values,
      email: values.email.replace(/[^0-9]/g, ''),
    });
  };

  return (
    <>
      <Box
        bordeRadius='12px'
        bgcolor='#FFF'
        borderRadius={isMobile ? '0' : '12px'}
        display={['block', 'block', 'flex']}
        flexDirection={'column'}
        justifyContent={'center'}
        width={isMobile ? '100%' : '520px'}
        padding={['28px', '60px']}
        height={isMobile ? '100%' : '90%'}
      >
        <Box maxWidth={['100%', '400px']} m='auto'>
          <Box textAlign='center' minWidth={['100%', '400px']}>
            {isMobile && (
              <img src='/images/login/LoginLogoMobile.png' alt='Logo' />
            )}
            <Typography
              fontSize={['28px', '32px']}
              fontWeight='bold'
              color='#222'
              mt={isMobile ? '32px' : '94px'}
              mb={4}
            >
              Crie sua conta
            </Typography>
          </Box>

          {/* INICIO DO FORMULARIO */}
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={registerSchema}
            validateOnBlur={true} // Garante validação ao sair do campo
            validateOnChange={true} // Valida enquanto o campo é alterado
            onSubmit={handleSubmitForm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldTouched,
              isValidating,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
              /* and other goodies */
            }) => {
              setformValues(values);
              getFormData(values);
              const isAllTouched = Object.keys(touched).every(
                (field) => touched[field]
              );
              const isFieldsValid =
                isAllTouched &&
                Object.keys(errors).every((field) => !errors[field]);

              const isAllValid = isFieldsValid && agreeWithTerms;

              if (isAllValid !== isFormValid) {
                setIsFormValid(isAllValid);
              }
              return (
                <Form>
                  <Box display='grid' gap={2}>
                    <Box>
                      <TGTextField
                        label='Nome*'
                        type='text'
                        name='nome'
                        id='nome'
                        placeholder='Digite o seu nome completo'
                        className={`form-control ${
                          errors.nome && touched.nome ? 'is-invalid' : ''
                        }`}
                      />
                    </Box>

                    {/* Campo Email */}
                    <Box>
                      <TGTextField
                        label='Email*'
                        type='text'
                        name='email'
                        id='email'
                        placeholder='Seu endereço de e-mail'
                        className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                        value={values.email} // Certifique-se de que "values.email" está definido corretamente
                        onChange={(e) => {
                          setFieldValue('email', e.target.value); // Atualiza o valor manualmente
                          setFieldTouched('confirmeEmail', true, false); // Revalida "confirmeEmail"
                        }}
                        onBlur={handleBlur} // Garante validação ao sair do campo
                      />
                      {errors.email && touched.email && (
                        <div className='invalid-feedback'>{errors.email}</div>
                      )}
                    </Box>

                    {/* Campo de Confirmação de Email */}
                    <Box>
                      <TGTextField
                        label='Confirme seu Email*'
                        type='text'
                        name='confirmeEmail'
                        id='confirmeEmail'
                        placeholder='Confirme endereço de e-mail'
                        className={`form-control ${
                          errors.confirmeEmail && touched.confirmeEmail
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={values.confirmeEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmeEmail && touched.confirmeEmail && (
                        <div className='invalid-feedback'>
                          {errors.confirmeEmail}
                        </div>
                      )}
                    </Box>

                    <Box>
                      <Typography fontSize='12px' color='#555' mb='4px'>
                        Senha*
                      </Typography>
                      <Box
                        position='relative'
                        display='flex'
                        alignItems='center'
                      >
                        <Box width='100%'>
                          <TGTextField
                            type={showPassword ? 'text' : 'password'}
                            name='senha'
                            id='senha'
                            placeholder='Defina uma senha'
                            className={`form-control ${
                              errors.senha && touched.senha ? 'is-invalid' : ''
                            }`}
                          />
                          {!errors.senha && (
                            <Box
                              onClick={togglePasswordVisibility}
                              sx={{
                                cursor: 'pointer',
                                fontSize: '12px',
                                color: '#555',
                                position: 'absolute',
                                right: '12px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                              }}
                            >
                              {showPassword ? 'Ocultar' : 'Mostrar'}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box display={'grid'}>
                      <TGCheckbox
                        onChange={() => setAgreeWithTerms(!agreeWithTerms)}
                        maxWidth='100%'
                        options={[
                          {
                            value: 'concordo',
                            label: '',
                            text: (
                              <Typography fontSize='12px'>
                                Concordo com os{' '}
                                <Typography
                                  component='a'
                                  href='https://checkout.ticketandgo.com.br/#/legal/termos-de-uso'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  color='neutral.20'
                                  fontSize='12px'
                                  display='inline'
                                  fontWeight='500'
                                >
                                  termos de uso
                                </Typography>{' '}
                                e a{' '}
                                <Typography
                                  component='a'
                                  href='https://checkout.ticketandgo.com.br/#/legal/aviso-de-privacidade'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  color='neutral.20'
                                  fontSize='12px'
                                  display='inline'
                                  fontWeight='500'
                                >
                                  Política de Privacidade
                                </Typography>{' '}
                                da Ticket and Go.
                              </Typography>
                            ),
                          },
                        ]}
                      />

                      <TGButton
                        sx={{ mt: 1 }}
                        type='submit'
                        disabled={!isAllValid}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          'Criar conta'
                        )}
                      </TGButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
          <Box mt='63px'>
            <Typography textAlign='center' color='#555'>
              Ja tem uma conta?{' '}
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#555',
                  fontWeight: 'bold',
                }}
                to='/login'
              >
                Entre agora
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RegisterContent;
