import { Box, Fade, Typography, useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import TGAlert from 'TGComponents/global/TGAlert'
import TGButton from 'TGComponents/global/TGButton'
import TGTextField from 'TGComponents/global/TGTextField'
import { Form, Formik } from 'formik'
import { postAPIOld } from 'pages/admin/afiliados/data/MyPrograms'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { store } from 'store'
import '../login.css'
import { recoveryCodeSchema, recoverySchema } from '../schemas/AuthSchema'
import appConfig from '../../../config'

const initialValues = {
  email: '',
  codigo: '',
  senha: '',
}

const RecoverContent = () => {
  const [formValues, setformValues] = useState(initialValues)
  const [isFormValid, setIsFormValid] = useState(false)
  const [formHasLength, setFormHasLenth] = useState(false)
  const isMobile = useMediaQuery('(max-width:1080px)')
  const [isRecovering, setIsRecovering] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [responseSendEmail, setResponseSendEmail] = useState('')
  const [passwordChangedWithSuccess, setPasswordChangedWithSuccess] =
    useState(false)
  const getFormData = (values) => {
    //console.log("getFormData::", values);
  }

  const navigate = useNavigate()
  const { logado } = useSelector((state) => ({
    logado: state.user.uuid || false,
  }))

  //Token e UUID da produtora
  const { user } = store.getState()
  const headers = useMemo(
    () => ({
      // uuidProdutora: `${user.roles[0].uuid_produtora}`,
      Authorization: `Bearer ${user.access_token}`,
    }),
    [user.access_token]
  )

  useEffect(() => {
    if (logado) navigate('/admin', { replace: true })
  }, [logado])

  //
  const recoverPassword = async (values) => {
    const emailWithoutMask = values.email
    const data = { email: emailWithoutMask }

    const response = await fetch(
      appConfig[appConfig.env].api + '/auth/solicitar-codigo-senha',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    setIsRecovering(true)
    setResponseSendEmail(response.message)
  }

  const updatePassword = async (values) => {
    const { codigo, senha } = values
    const emailWithoutMask = values.email

    const data = { codigo, email: emailWithoutMask, senha }

    await fetch(appConfig[appConfig.env].api + '/auth/recuperar-senha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    setPasswordChangedWithSuccess(true)
    setTimeout(() => {
      navigate('/login')
    }, 2000)
  }

  const handleRecoverySubmit = async (values) => {
    if (isRecovering) {
      setPasswordChangedWithSuccess(true)
      await updatePassword(values)
    } else {
      await recoverPassword(values)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setPasswordChangedWithSuccess(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  return (
    <>
      <Box
        bordeRadius='12px'
        bgcolor='#FFF'
        borderRadius={isMobile ? '0' : '12px'}
        display={['block', 'block', 'flex']}
        flexDirection={'column'}
        justifyContent={'center'}
        width={isMobile ? '100%' : '520px'}
        padding={['28px', '60px']}
        height={isMobile ? '100%' : '90%'}
      >
        <Box maxWidth={['100%', '400px']} m='auto'>
          <Box textAlign='center' minWidth={['100%', '400px']}>
            {isMobile && (
              <img src='/images/login/LoginLogoMobile.png' alt='Logo' />
            )}{' '}
            <Typography
              fontSize={['28px', '32px']}
              fontWeight='bold'
              color='#222'
              mt={isMobile ? '32px' : '94px'}
              mb='16px'
            >
              {!isRecovering ? 'Esqueceu a senha' : 'E-mail enviado'}
            </Typography>
            <Typography fontSize='14px' color='#555' mb='32px'>
              {!isRecovering
                ? 'Insira o seu e-mail que enviaremos um código. Utilize o código para redefinir a sua senha.'
                : responseSendEmail}
            </Typography>
          </Box>

          {/* INICIO DO FORMULARIO */}
          {!isRecovering ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={recoverySchema}
              onSubmit={handleRecoverySubmit}
            >
              {({ values, errors, touched, isSubmitting, handleChange }) => {
                setformValues(values)
                getFormData(values)
                const isAllTouched = Object.keys(touched).every(
                  (field) => touched[field]
                )
                const isFieldsValid =
                  isAllTouched &&
                  Object.keys(errors).every((field) => !errors[field])

                const isAllValid = isFieldsValid && formHasLength

                if (isAllValid !== isFormValid) {
                  setIsFormValid(isAllValid)
                }
                return (
                  <Form>
                    <Box display='grid'>
                      <Box pb='32px'>
                        {/* <TGTextField
                          label='CPF*'
                          type='text'
                          name='cpf'
                          id='cpf'
                          mask='999.999.999-99'
                          placeholder='CPF'
                          onChange={(e) => {
                            handleChange(e)
                            setFormHasLenth(e.target.value.length > 0)
                          }}
                          className={`form-control ${
                            errors.cpf && touched.cpf ? 'is-invalid' : ''
                          }`}
                        /> */}

                        <TGTextField
                          label="EMAIL*"
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className={`form-control ${
                            errors.email && touched.email ? 'is-invalid' : ''
                          }`}
                          style={{
                            height: '49px',
                          }}
                          onChange={(e) => {
                            handleChange(e)
                            setFormHasLenth(e.target.value.length > 0)
                          }}

                          mask={/^\S+@\S+\.\S+$/} // Regex for email validation
                          // onBlur={(e) => {
                          //   if (!/^\S+@\S+\.\S+$/.test(e.target.value)) {
                          //     setFieldError('email', 'Invalid email format');
                          //   }
                          // }}
                        />
                      </Box>

                      <TGButton
                        type='submit'
                        fontWeight='bold'
                        disabled={!isAllValid}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          'Recuperar'
                        )}
                      </TGButton>
                    </Box>
                  </Form>
                )
              }}
            </Formik>
          ) : (
            // Parte para enviar o codigo
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={recoveryCodeSchema}
              onSubmit={handleRecoverySubmit}
            >
              {({ values, errors, touched, isSubmitting, handleChange }) => {
                setformValues(values)
                getFormData(values)
                const isAllTouched = Object.keys(touched).every(
                  (field) => touched[field]
                )
                const isFieldsValid =
                  isAllTouched &&
                  Object.keys(errors).every((field) => !errors[field])

                const isAllValid = isFieldsValid && formHasLength

                if (isAllValid !== isFormValid) {
                  setIsFormValid(isAllValid)
                }
                return (
                  <Fade in={isRecovering} timeout={1500}>
                    <Form>
                      <Box display='grid' gap={2}>
                        <Box>
                          <TGTextField
                            label='Code*'
                            type='text'
                            name='codigo'
                            id='username'
                            mask='99-99-99'
                            placeholder='000000'
                            onChange={(e) => {
                              handleChange(e)
                              setFormHasLenth(e.target.value.length > 0)
                            }}
                            className={`form-control ${
                              errors.codigo && touched.codigo
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                        </Box>

                        <Box mb='16px'>
                          <Typography fontSize='12px' color='#555' mb='4px'>
                            Nova Senha*
                          </Typography>
                          <Box
                            position='relative'
                            display='flex'
                            alignItems='center'
                          >
                            <Box width='100%'>
                              <TGTextField
                                type={showPassword ? 'text' : 'password'}
                                name='senha'
                                id='senha'
                                placeholder='Defina uma senha'
                                className={`form-control ${
                                  errors.senha && touched.senha
                                    ? 'is-invalid'
                                    : ''
                                }`}
                              />
                              {!errors.senha && (
                                <Box
                                  onClick={togglePasswordVisibility}
                                  sx={{
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    color: '#555',
                                    position: 'absolute',
                                    right: '12px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                  }}
                                >
                                  {showPassword ? 'Ocultar' : 'Mostrar'}
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <TGButton
                          type='submit'
                          fontWeight='bold'
                          disabled={!isAllValid}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={20} />
                          ) : (
                            <span>
                              {!isRecovering
                                ? 'Recuperar'
                                : 'Atualizar minha senha'}
                            </span>
                          )}
                        </TGButton>
                      </Box>
                    </Form>
                  </Fade>
                )
              }}
            </Formik>
          )}

          <Box mt={isMobile ? '83px' : '133px'}>
            <Typography textAlign='center' color='#555'>
              Voltar para{' '}
              <Link
                style={{
                  textDecoration: 'none',
                  color: '#555',
                  fontWeight: 'bold',
                }}
                to='/login'
              >
                tela de login
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <TGAlert
        open={passwordChangedWithSuccess}
        handleClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        message='Senha alterada com sucesso!'
        type='success'
      />
    </>
  )
}

export default RecoverContent
