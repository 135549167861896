import React, { useEffect, useState } from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import TGSimpleSlider from './TGSimpleSlider';
import { TGTooltip } from 'TGComponents/layout/TGTobBar/stylesMobile';

const TGTabs = ({
  openTab = 0,
  setOpenTab,
  tabContents,
  padding,
  mobileIcon,
  marginTop = '28px',
  margin,
  px,
  noMobileStyle,
  onTabIndexChange,
  displayTGTabs,
  clearState, // funcao opcional para limpar estados
  overflowXMd,
  styleSlider,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    setTabIndex(openTab);
  }, [openTab]);
  const handleTabChange = (newTabIndex) => {
    setTabIndex(newTabIndex);

    // Get the current hash from the URL
    const currentHash = window.location.hash;

    // Extract the part of the URL before any query parameters (if any)
    const [baseHash, hashParams] = currentHash.split('?');

    // Create or update the search params
    const searchParams = new URLSearchParams(hashParams || '');
    searchParams.set('tab', newTabIndex); // Set or update the tab param

    // Create the new hash with the updated search params
    const newHash = `${baseHash}?${searchParams.toString()}`;

    // Update the hash in the URL without reloading the page
    window.history.replaceState(null, '', newHash);

    if (setOpenTab) {
      setOpenTab(newTabIndex);
    }
    if (clearState) {
      clearState();
    }
    if (typeof onTabIndexChange === 'function') {
      onTabIndexChange(newTabIndex);
    }
  };

  const borderColors = ['#00B2FF', '#FF9D60', '#8960FF'];

  const getTabStyle = (index) => {
    const isSelected = tabIndex === index;
    const borderColor = isMobile && !noMobileStyle ? '#FAFAFA' : 'transparent';
    const backgroundColor = isSelected ? borderColor : 'transparent';

    return {
      display: 'inline-block',
      paddingLeft: 0,
      marginRight: '16px',
      minWidth: isMobile && !noMobileStyle ? '300px' : 'auto',
      paddingBlock: isMobile && !noMobileStyle ? '16px' : '0',
      textIndent: isMobile && !noMobileStyle ? '16px' : '',
      borderLeft:
        isMobile && !noMobileStyle
          ? `16px solid ${borderColors[index]}`
          : 'none',
      borderRadius: '12px',
      marginBottom: isMobile && !noMobileStyle ? '14px' : '',
      backgroundColor: isMobile && !noMobileStyle ? '#FAFAFA' : 'transparent',
    };
  };

  const tabLabelStyle = (isSelected) => ({
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: isSelected ? '#000' : '#999',
    fontWeight: isSelected ? '600' : '500',
  });

  const indicatorStyle = {
    height: '3px',
    borderRadius: '4px',
    maxWidth: '80%',
    backgroundColor: isMobile && !noMobileStyle ? 'transparent' : '#00B2FF',
    marginTop: isMobile && !noMobileStyle ? '0' : '8px',
  };
  const indicatorStyleBack = {
    width: '100%',
    height: '2px',
    borderRadius: '4px',
    backgroundColor: 'rgba(34, 34, 34, 0.08)',
    marginTop: isMobile && !noMobileStyle ? '0' : '8px',
  };

  return (
    <div id='teste'>
      <Box
        px={px}
        display={displayTGTabs}
        style={{ marginTop: marginTop }}
        position='relative'
        padding={padding}
        margin={margin}
      >
        <TGSimpleSlider style={styleSlider} overflowXMd={overflowXMd}>
          {tabContents.map((tab, index) => {
            if (!tab) return null; // Skip null or undefined tabs

            const { mobileIcon, key, disabled } = tab;

            return (
              <TGTooltip
                key={key || index}
                title={disabled ? 'Bloqueado' : null}
                placement='top'
              >
                <Box
                  sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                  style={getTabStyle(index)}
                  onClick={disabled ? null : () => handleTabChange(index)}
                >
                  <Box display='flex'>
                    {isMobile && !noMobileStyle && mobileIcon && (
                      <img
                        src={mobileIcon}
                        alt={key}
                        style={{ marginLeft: '16px' }}
                      />
                    )}
                    <Typography sx={tabLabelStyle(tabIndex === index)}>
                      {key}
                    </Typography>
                  </Box>
                  {tabIndex === index && <Box style={indicatorStyle} />}
                </Box>
              </TGTooltip>
            );
          })}
        </TGSimpleSlider>
        {!noMobileStyle ? (
          <>
            {' '}
            {!isMobile && (
              <Box
                position={'absolute'}
                top='27px'
                style={indicatorStyleBack}
              />
            )}
          </>
        ) : (
          <>
            <Box position={'absolute'} top='27px' style={indicatorStyleBack} />
          </>
        )}
      </Box>

      {tabContents?.map(({ content }, index) => (
        <div
          key={index}
          style={{
            display: tabIndex === index ? 'block' : 'none',
          }}
        >
          {tabIndex === index ? content : null}
        </div>
      ))}
    </div>
  );
};

export default TGTabs;
