export const UserIcon = ({ size = 24, color }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.50104 0.799805C6.52877 0.799805 4.94102 2.39598 4.94102 4.3598C4.94102 6.28251 6.4567 7.84857 8.36755 7.9128C8.3924 7.91363 8.41727 7.91292 8.44203 7.91067C8.48111 7.90712 8.52276 7.90764 8.54801 7.91016C8.57454 7.91281 8.60122 7.91369 8.62786 7.9128C10.5391 7.84855 12.053 6.28262 12.061 4.3623L12.061 4.3598C12.061 2.3951 10.4657 0.799805 8.50104 0.799805ZM6.14101 4.3598C6.14101 3.05696 7.19326 1.9998 8.50104 1.9998C9.8026 1.9998 10.8604 3.05718 10.861 4.35858C10.8551 5.62661 9.86653 6.65316 8.62026 6.71215C8.54015 6.70665 8.45623 6.70693 8.37652 6.71222C7.12875 6.65388 6.14101 5.62651 6.14101 4.3598Z'
        fill='#FAFAFA'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.6236 9.27425C11.5906 8.58434 9.91669 8.2582 8.61613 8.2582C7.31537 8.2582 5.41757 8.5844 4.37882 9.27313L4.37662 9.27461C3.44568 9.8978 2.90039 10.7772 2.90039 11.7324C2.90039 12.6877 3.44584 13.5656 4.37733 14.1856C5.41329 14.8785 7.31011 15.2065 8.61113 15.2065C9.9124 15.2065 11.588 14.8784 12.624 14.1852C13.555 13.562 14.1004 12.6826 14.1004 11.7274C14.1004 10.7721 13.555 9.89422 12.6236 9.27425ZM4.10039 11.7324C4.10039 11.2695 4.36149 10.7292 5.04307 10.2725L11.9566 13.1879C11.1593 13.7214 9.72982 14.0065 8.61113 14.0065C7.49239 14.0065 5.84134 13.7213 5.04405 13.1879L5.04273 13.187C4.36133 12.7337 4.10039 12.1951 4.10039 11.7324ZM5.04307 10.2725L11.9566 13.1879C12.6389 12.7311 12.9004 12.1905 12.9004 11.7274C12.9004 11.2646 12.6395 10.7261 11.958 10.2727L11.9572 10.2722C11.1635 9.74207 9.73557 9.4582 8.61613 9.4582C7.49739 9.4582 5.84422 9.74174 5.04307 10.2725Z'
        fill='#FAFAFA'
      />
    </svg>
  );
};
