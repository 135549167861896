import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import pallete from 'TGComponents/theme/pallete';

const CustomSnackbar = styled(Snackbar)(({ theme, isMobile }) => ({
  '& .MuiPaper-root': {
    width: isMobile && '100%',
    backgroundColor: '#fff',
    color: '#000',
    border: `1px solid ${pallete.base['translucentBlack']}`,
    boxShadow: '0px 4px 8px 0px #0000001f',
    borderRadius: '8px',
    padding: '20px 24px',
  },
}));

const CustomAlert = styled(Alert)(({ theme }) => ({
  'backgroundColor': 'transparent',
  'color': '#222',
  'padding': '0',
  'fontWeight': 500,
  'fontSize': '16px',
  '& .MuiAlert-icon': {
    display: 'none',
  },
  '& .MuiAlert-message': {
    padding: '8px 30px 0px 0px',
  },
}));

const TGAlert = ({
  open,
  handleClose,
  anchorOrigin,
  message,
  type,
  tgCustom = false,
  description,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const colorType = {
    success: pallete.green[500],
    error: pallete.red[600],
    warning: '#F5910D',
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength - 3) + '...';
    }
    return text;
  };

  return (
    <>
      {tgCustom ? (
        <CustomSnackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={anchorOrigin}
          onClose={handleClose}
          isMobile={isMobile}
        >
          <CustomAlert onClose={handleClose} severity={type}>
            <Box
              width='100px'
              height='4px'
              bgcolor={colorType[type]}
              mb='18px'
              borderRadius='480px'
            />
            <Typography fontSize='1rem' fontWeight={500} color={'#222'}>
              {message || ''}
            </Typography>
            <Typography
              color={pallete.base['blackAlpha']}
              fontWeight={400}
              fontStyle='normal'
              fontSize='0.82rem'
              mt='8px'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {description}
            </Typography>
          </CustomAlert>
        </CustomSnackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={anchorOrigin}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default TGAlert;
