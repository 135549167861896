import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';

export default function TGSimpleSlider({
  children,
  pb = '5px',
  gap = '16px',
  widthFilter,
  displaySlider = 'flex',
  overflowXMd = 'initial',
  ...props
}) {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
    document.body.style.userSelect = 'none'; // Evita seleção de texto durante o arrasto
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
    document.body.style.userSelect = 'auto'; // Restaura a seleção de texto após o arrasto
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1.5; // Multiplica por 1.5 para aumentar a sensibilidade
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Box
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseLeaveOrUp}
      onMouseLeave={handleMouseLeaveOrUp}
      onMouseMove={handleMouseMove}
      sx={{
        'display': displaySlider,
        'gap': gap,
        'scrollSnapType': 'x mandatory',
        'pb': pb,
        'overflowX': { xs: 'auto', md: overflowXMd },
        'width': widthFilter || '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        'scrollbarWidth': 'none',
        '-ms-overflow-style': 'none',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
